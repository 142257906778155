import 'katex/dist/katex.min.css';
import Latex from 'react-latex-next';
import Accordion from 'react-bootstrap/Accordion'
import { Card, Spinner } from 'react-bootstrap';
import { BiError } from "react-icons/bi";
import LatexRenderer from './components/latexRenderer';

export default function XMLParser({ xmlString, isFinished, isFailed }) {

      const BlinkingText = ({ text }) => {
            return (
                  <div className="blinking-text">
                    <div className="blinking-content">
                      {text}
                      <span className="blinking-dots"></span>
                    </div>
                  </div>
                );
          };


          if (!isFinished) {
            // When isFinished is false, show the blinking text "Solving"
            return <BlinkingText text="Solving" />;
          }

          if (isFinished && isFailed) {
            // When isFinished is true and isFailed is true, return nothing
            return null;
          }

      const accordionStyle = {
            backgroundColor: '#f0f0f0', // Replace with your desired background color
          };
      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(xmlString, 'application/xml');
      //   xmlDoc.getElementsByTagName("type").item(0).textContent
      const innerXml = xmlDoc.documentElement;

  const parseNode = (node) => {
      const children = Array.from(node.childNodes);

      return children.map((child, index) => {
        if (child.nodeName === "layer"){
            let layerType = child.getElementsByTagName("type").item(0).textContent;
            if (layerType === "Str" ){
                  let logTextLatex = child.getElementsByTagName("logtext").item(0).textContent.replace("\\(","$").replace("\\)","$").replaceAll("\\n ", "\n");
                  return (
                        <div key={index} className="latex-container"  style={{paddingTop: "1rem", paddingBottom: "1rem", width: "100vh" }}>
                            <LatexRenderer content={logTextLatex}/>
                        </div>
                      );

            }
            else if(layerType === "ProcessLog"){
                  let logTextLatex = child.getElementsByTagName("logtext").item(0).textContent.replaceAll("\\n ", "\n");
                  return (
                        <div key={index}>
                              <Card>
                                    <Card.Body >
                                    <LatexRenderer content={logTextLatex}/>
                                    </Card.Body>
                              </Card>
                        </div>
                      );

            }
            else if(layerType === "Horizontal" || layerType === "Expand"){
                  let childNodes = child.childNodes;
                  let inputLatex = "";
                  let outputLatex = "";
                  let processName = "";

                  let steps;
                  for (let j = 0; j < childNodes.length; j++) {
                        if (childNodes[j].nodeName === "inputlatex"){
                              inputLatex = childNodes[j].textContent.replace("\\(","$").replace("\\)","$");
                        }
                        else if (childNodes[j].nodeName === "outputlatex"){
                              outputLatex = childNodes[j].textContent.replace("\\(","$").replace("\\)","$");
                        }
                        else if (childNodes[j].nodeName === "substep"){
                              steps = childNodes[j];
                        }
                        else if (childNodes[j].nodeName === "processname"){
                              processName = childNodes[j].textContent.replace("\\(","$").replace("\\)","$");
                        }
                  }
                  return (
                        <div className="accordion" key={index}>

                              <div className="latex-container" style={{marginTop: "1.5rem", marginBottom: "1.5rem"}}><Latex>{inputLatex.replace(/\n/g,"<br>")}</Latex>
                              </div>
                              <Accordion className='childAccordion'>
                                    <Accordion.Item eventKey={index}>
                                         <Accordion.Header><div className="latex-container"><Latex>{processName.replace(/\n/g,"\n")}</Latex></div></Accordion.Header>
                                          <Accordion.Body>
                                                {parseNode(steps)}
                                          </Accordion.Body>

                                    </Accordion.Item>
                              </Accordion>
                              <div className="latex-container" style={{marginTop: "1.5rem", marginBottom: "1.5rem"}}><Latex>{outputLatex.replace(/\n/g,"<br>")}</Latex></div>
                        </div>
                      );
            }
            else {
                  return null;
                }
        }
        else {
            return null;
          }


      }

      );
    };
/*   const parseNode = (nodeList, lastLog="") => {
      let template = [];
      console.log("nodelength", nodeList.length);
      for (let i = 0; i < nodeList.length; i++) {
            let node = nodeList[i];

            if (node.nodeName === "layer"){
                  let layerType = node.getElementsByTagName("type").item(0).textContent;
                  if (layerType === "Str" ){
                        let logTextLatex = node.getElementsByTagName("logtext").item(0).textContent;
                        console.log("logTextLatex", logTextLatex);
                        template.push(<p key={id}>"logTextLatex" + {logTextLatex}</p>
                        );
                        id += 1;

                  }
                  else if(layerType === "ProcessLog"){
                        let logTextLatex = node.getElementsByTagName("logtext").item(0).textContent;
                        template.push(  <p key={id}>{logTextLatex}</p>);
                        id += 1;


                  }

                  else if(layerType === "Horizontal" || layerType === "Expand"){
                        let childNodes = node.childNodes;
                        let inputLatex = "";
                        let outputLatex = "";
                        let steps;
                        for (let j = 0; j < childNodes.length; j++) {
                              if (childNodes[j].nodeName === "inputlatex"){
                                    inputLatex = childNodes[j].textContent;
                              }
                              else if (childNodes[j].nodeName === "outputlatex"){
                                    outputLatex = childNodes[j].textContent;
                              }
                              else if (childNodes[j].nodeName === "substep"){
                                    steps = childNodes[j].childNodes;
                              }
                        }
                        console.log(steps);
                        id += 1;
                        template.push(<div>
                              <p key={id-1}>"inputLatex" + {inputLatex}</p>
                              {parseNode(steps)}

                              <p key={id}>"outputLatex" + {outputLatex}</p>
                              </div>
                        );
                  }



            }

          }
          console.log("tem", template);
          return <div>{template}</div>;
  }; */


  return (
      <Accordion className='mainAccordion' button="disabled">
            <Accordion.Item eventKey="0">
              <Accordion.Header>{isFinished === true ? (isFailed === true ? <div className="mainHeader"><BiError size={24}/>
                  {' '}Failed</div> : <div className="mainHeader">{'  '}Show steps</div>) : <div className="mainHeader"><Spinner animation="border" size='md'/>{'  '}Solving</div>}
              </Accordion.Header>
              <Accordion.Body>
                    {parseNode(innerXml)}
              </Accordion.Body>

            </Accordion.Item>
      </Accordion>
  );
}

