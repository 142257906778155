import AnimatedButton from '../components/inputField';
import { useState, useRef, useEffect } from 'react';
import { getStreamingCompletion, getSolverApiResponse, getGeminiResponse } from '../server';

import 'katex/dist/katex.min.css'
import XMLParser from '../createApiPart';
import AuthModal from '../components/authAlter';
import tlogo from "../assets/resultCropped.png"
import 'katex/dist/katex.min.css';
import Latex from 'react-latex-next';
import LatexRenderer from '../components/latexRenderer';
import Button from 'react-bootstrap/Button';
import { auth, analytics } from '../firebase/firebaseAuth';

import 'reactjs-popup/dist/index.css';
import { ref, push, set, child } from "firebase/database";
import { db } from '../firebase/realtimeDatabase';
import { AiOutlineThunderbolt } from "react-icons/ai";
import Question from '../components/questionComponent';
import SubscriptionModal from '../components/subscriptionModal';

function Chats({userUID, isMobile, credits, updateCredits, modalShow, updateModalState, updateAuthState, isSubscribed, isLogged, subscriptionShow, updateSubscriptionState, handleAnonymousSignIn}) {
  const [questAnswerPairs, setQuestAnswerPairs] = useState([]);
  const isSessionFinished = useRef(true);

  const [sessionState, setSessionState] = useState([isSessionFinished.current]);
  const answerPartId = useRef(0);
  const questAnswerPairsId = useRef(-1);
  const div = useRef(null)
  useEffect(()=> div.current.scrollIntoView({behavior: "smooth", block:"end"}), [questAnswerPairs])

  const sampleQuestions =[
     {textInput: "Determine $f(x)$ given that $f'(x) = 12x^2 - 4x$ and $f(-3) = 17$."},
     {textInput: "The sum of two numbers $x$ and $y$ is 153, and the value of the fraction $\\frac{x}{y}$ is 0.7. What is the value of $y - x$?"},
     {textInput: "A line goes through point $A(9, 1)$, point $B(19, k)$ and point $C(7, 0)$. What is the value of $k$?"}

  ] ;



  let apiPart = "";
  let isAPIStarted = false;
  const stop = ["}\n```"];

  const handleSend = async (input) => {
    if (!isSubscribed){
      if(userUID === null){
        await handleAnonymousSignIn();
        userUID = auth.currentUser.uid;
      }

      if (credits <= 0){
        if(isLogged){
          updateSubscriptionState(true);
          return <SubscriptionModal show={subscriptionShow} handleClose={() => updateSubscriptionState(false)} uid={userUID}/>

        }
        else{
          updateModalState(true);
          return <AuthModal show={modalShow} handleClose={() => updateModalState(false)} updateAuthState={updateAuthState} uid={userUID} handleAnonymousSignIn={handleAnonymousSignIn}/>
      ;
        }

      }
      else{
        updateCredits(credits -1, userUID);

      }
    }

    let index = 0;
    isSessionFinished.current = false;
    setSessionState(false);

    const currentQstAnsPairID = questAnswerPairsId.current;
    const currentDate = new Date();
    const dateString = currentDate.toISOString();
    let quest = {};
    if (input['croppedImage']){
      setQuestAnswerPairs(qa => [...qa, {id: currentQstAnsPairID, date: dateString, quest: {quest_image: input['croppedImage'], quest_text: null, ocr_finished: false}, answer: [], answerAsLabelled:[]}]);
      const result = await getGeminiResponse({_messages: input['imageInput']});
      const response = result.response;
      //setQuestAnswerPairs(qa => [...qa, {id: currentQstAnsPairID, date: dateString, quest: input['croppedImage'], answer: [], answerAsLabelled:[]}]);
      quest = {quest_image: input['croppedImage'], quest_text: response.text(), ocr_finished: true}
      setQuestAnswerPairs(qaPairs => qaPairs.map(qa =>{
        if (qa.id === currentQstAnsPairID) {
          return {id: currentQstAnsPairID, date: dateString, quest: quest, answer: [], answerAsLabelled:""};
        }
        else{
          return qa;
        }
      }));
    }
    else{
      quest = {quest_image: null, quest_text: input['textInput'], ocr_finished: false}

      setQuestAnswerPairs(qa => [...qa, {id: currentQstAnsPairID, date: dateString, quest: quest, answer: [], answerAsLabelled:[]}]);
    }



    let _answerAsLabelled = "";
    let messages = [
    {role: 'user', content: quest.quest_text}]
    let assistantMssg = "";
    let fullResponse = "";
    let answerParts = [];
    while(answerPartId.current < 17){
      const currentId = answerPartId.current;
      answerParts.push({id: currentId, type: "llm", value: "", isFinished: false, isFailed: false});
      setQuestAnswerPairs(qaPairs => qaPairs.map(qa =>{
        if (qa.id === currentQstAnsPairID) {
          return {id: currentQstAnsPairID, date: dateString, quest: quest, answer: [...qa.answer, answerParts[currentId]], answerAsLabelled:""};
        }
        else{
          return qa;
        }
      }));

      const stream = await getStreamingCompletion({_messages: messages, modelName: "ft"});
  //answerParts = [...answerParts, {id: index, type: "llm", value: "", isFinished: false}]
      for await (const chunk of stream){
        assistantMssg += chunk.choices[0]?.delta?.content || "";
        if (chunk.choices[0]?.delta?.content === "```" ){
          isAPIStarted = true;
          continue;
        }
        else if (isAPIStarted){
          apiPart += chunk.choices[0]?.delta?.content || "";
          continue;
        }
        fullResponse += chunk.choices[0]?.delta?.content || "";

        answerParts[currentId] = {id: currentId, type: "llm", value: fullResponse, isFinished: false};

        setQuestAnswerPairs(qaPairs => qaPairs.map(qa =>{
          if (qa.id === currentQstAnsPairID) {
            const ansUpt = (qa.answer.map(answerpart => {
              if (answerpart.id === currentId) {
                  return answerParts[currentId];
                }
              else{
                return answerpart;
              }
            }));
            return {id: currentQstAnsPairID, date:dateString, quest: quest, answer: ansUpt, answerAsLabelled:""}

          }
          else{
            return qa;
          }
        }));

        //updateAnswer(index, fullResponse);

        }

  if (isAPIStarted){
    answerPartId.current = answerPartId.current + 1;
    const currentId = answerPartId.current;

    //answerParts = [...answerParts, {id: index, type: "api", value: "", isFinished: false}];
    // insertAnswer(index);
    answerParts[currentId] = {id: currentId, type: "api", value: "", isFinished: false, isFailed: false};

    setQuestAnswerPairs(qaPairs => qaPairs.map(qa =>{
      if (qa.id === currentQstAnsPairID) {
        return {id: currentQstAnsPairID, date:dateString, quest: quest, answer: [...qa.answer, answerParts[currentId]], answerAsLabelled:""};
      }
      else{
        return qa;
      }
    }));

    let responseJson = "";
    assistantMssg += stop[0];

    apiPart += "}";
    apiPart = apiPart.replace("json\n", "").replace(/\\/g,"\\\\");

    apiPart = JSON.parse(apiPart);
    if ('name' in apiPart){
      const currentId = answerPartId.current;
      let apiValue = undefined;
      let apiReturnedJson =  undefined;
      let isHasFailed = undefined;
      try{
      const response = await getSolverApiResponse({_apiPart: apiPart});
      if (response.status === 200 || response.status === 404){
        const data = await response.json();
        apiValue = data[1][0];
        apiReturnedJson =  data[0];
        isHasFailed = response.status === 200  ? false : true;

      //  await updateAnswer(index, data[1][0]);
      responseJson = "```json\n" + data[0] + "\n```"

      }
      else{
        apiValue = ["0", "0", "0"];
        isHasFailed = true;
        if (apiPart.name === "solve")
        {
          responseJson = "```json\n" + apiReturnedJson + "\n```"

        }
        else{
          responseJson = "```json\n{\n  \"simplified_expression\": \"NaN\",\n  \"status\": \"failed\",\n  \"message\": \"Simplification has failed. Simplify this part step by step without using tools\"\n}\n```"

        }
        responseJson = "```json\n{\n  \"solutions\": \"NaN\",\n  \"status\": \"failed\",\n  \"message\": \"Solutions couldn't found. Solve this part step by step without using tools\"\n}\n```"
        //answerParts[index] = {id: answerPartId, type: "api", value: "", isFinished: true};
      }
    }
    catch(error){
        apiValue = ["0", "0", "0"];
        isHasFailed = true;
        if (apiPart.name === "solve")
        {
          responseJson = "```json\n" + apiReturnedJson + "\n```"

        }
        else{
          responseJson = "```json\n{\n  \"simplified_expression\": \"NaN\",\n  \"status\": \"failed\",\n  \"message\": \"Simplification has failed. Simplify this part step by step without using tools\"\n}\n```"

        }
        responseJson = "```json\n{\n  \"solutions\": \"NaN\",\n  \"status\": \"failed\",\n  \"message\": \"Solutions couldn't found. Solve this part step by step without using tools\"\n}\n```"
    }

    answerParts[currentId] = { id: currentId, type: "api", value: apiValue, isFinished: true, isFailed: isHasFailed };

     setQuestAnswerPairs(qaPairs => qaPairs.map(qa =>{
      if (qa.id === currentQstAnsPairID) {
        const ansUpt = (qa.answer.map(answerpart => {
          if (answerpart.id === currentId) {
              return answerParts[currentId];
            }
          else{
            return answerpart;
          }
        }));
        return {id: currentQstAnsPairID, date:dateString, quest: quest, answer: ansUpt, answerAsLabelled:""}

      }
      else{
        return qa;
      }
    }));
      assistantMssg += "\n" + responseJson + "\n";
      messages.push({role: "assistant", content: assistantMssg});
      _answerAsLabelled +=  assistantMssg + "\n";
      if (isHasFailed){

        let opQuestion = "";
        if (apiPart.name === "solve"){
          opQuestion = apiPart.equations.join(", ") + " solve for " + apiPart.desired_variables.join(" and ")

        }
        else {
          opQuestion = "Simplify " + apiPart.expression
        }
        let OpMessage = [
          {role: 'user', content: opQuestion}]
        answerPartId.current = answerPartId.current + 1;
        const currentIdOp = answerPartId.current;

        answerParts[currentIdOp] = {id: currentIdOp, type: "llm", value: "", isFinished: false, isFailed: false};

        setQuestAnswerPairs(qaPairs => qaPairs.map(qa =>{
          if (qa.id === currentQstAnsPairID) {
            return {id: currentQstAnsPairID, date: dateString, quest: quest, answer: [...qa.answer, answerParts[currentIdOp]], answerAsLabelled:""};
          }
          else{
            return qa;
          }
        }));

        let fullResponseOp = "";
        const streamOp = await getStreamingCompletion({_messages: OpMessage, modelName: "op"});
        for await (const chunkOp of streamOp){
          fullResponseOp += chunkOp.choices[0]?.delta?.content || "";

        answerParts[currentIdOp] = { id: currentIdOp, type: "llm", value: fullResponseOp, isFinished: false, isFailed: false };

        setQuestAnswerPairs(qaPairs => qaPairs.map(qa =>{
          if (qa.id === currentQstAnsPairID) {
            const ansUpt = (qa.answer.map(answerpart => {
              if (answerpart.id === currentIdOp) {
                  return answerParts[currentIdOp];
                }
              else{
                return answerpart;
              }
            }));
            return {id: currentQstAnsPairID, date:dateString, quest: quest, answer: ansUpt, answerAsLabelled:""}

          }
          else{
            return qa;
          }
        }));
        }
        messages.push({role: "assistant", content: fullResponseOp});
        _answerAsLabelled +=  fullResponseOp + "\n";





      }




    }

  }

  else{
    _answerAsLabelled +=  assistantMssg + "\n"
    break;
  }
  // setAnswer(answerParts);


  answerPartId.current = answerPartId.current + 1;
  apiPart = "";
  isAPIStarted = false;
  assistantMssg = "";
  fullResponse = "";

}

push(ref(db, 'users/' + userUID + '/chats'), {id: currentQstAnsPairID, date: dateString, quest: quest, answer: answerParts, answerAsLabelled: _answerAsLabelled});

  isSessionFinished.current = true;
  setSessionState(true);
  answerPartId.current = 0;
  questAnswerPairsId.current = questAnswerPairsId.current + 1
  }

  const sampleItems = sampleQuestions.map((sq,id) =>
    <Button className="example-btn" variant="outline-light" size='lg' onClick={() => {handleSend(sq)}} key={id}><span>{sq.textInput.replace(/\\\\/g,"\\")}</span></Button>
  );


  return (
    <div className="main">
          { questAnswerPairsId.current === -1 && sessionState &&

          <div className="container">
          <img src={tlogo} alt="Logo" className="banner-logo" />
          <h1 className="main-banner">Next-Gen Math Solver</h1>
          <p className="sub-banner">AI with Step-by-Step Precision</p>

          </div>

          }

          {!isSubscribed && <div  className="credits"><AiOutlineThunderbolt size={28}/> <span>{''+ credits}</span></div>}

        <div className={isMobile ? "chatsMobile": "chats"}  ref={div} style={{zIndex:"0"}}>
        {
          questAnswerPairs.map((qa, id) => (<div key={id}>
            <div className="chat">
              <Question questInput={qa.quest}/>
            <div className="answer">
              {qa.answer.map( a => (<div key={a.id}>
              {a.type === "api" ? <XMLParser xmlString={a.value} isFinished={a.isFinished} isFailed={a.isFailed}></XMLParser> :
              <LatexRenderer content={a.value}/>}
             </div>))}
            </div>
             </div></div>))
        }

        </div>


        <div className={isMobile ? "chatFooterMobile": "chatFooter"} style={{zIndex:"2"}}>
        { questAnswerPairsId.current === -1 && sessionState &&
<div className="container">
          <div  className='examples-holder' >

           {sampleItems}
           </div>
          </div>}
          <AnimatedButton onSend={handleSend} isFinished={sessionState} credits={credits}/>

        </div>


    </div>
  );
}

export default Chats;
