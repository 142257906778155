import OpenAI from 'openai';
import { getVertexAI, getGenerativeModel } from "firebase/vertexai-preview";
import firebase from 'firebase/compat/app';
import firebaseConfig from "./firebase/firebaseConfig";

// Initialize FirebaseApp
const firebaseApp = firebase.initializeApp(firebaseConfig);

// Initialize the Vertex AI service
const vertexAI = getVertexAI(firebaseApp);
const generationConfig = {
  max_output_tokens: 400,
  temperature: 0.0,
  top_p: 0.0,
  top_k: 1,
};
const geminiModel = getGenerativeModel(vertexAI, { model: "gemini-1.5-flash-002", generationConfig });

const geminiPrompt = "Act like a text - latex scanner. Extract text - latex, graphs, geometric shapes, tables as it is without analyzing it and without summarizing it. For graphs,  geometric shapes, charts give detailed explanations . Give output without extra notes."

export const getGeminiResponse = async ({ _messages }) => {
  return geminiModel.generateContent([geminiPrompt,_messages]);
};

//const promptFt = "Integrate step-by-step reasoning to solve math problems using the following guidelines:\n-Analyze the question and create steps to solve the problem.\n-Use 'simplify' api when you need to calculate/simplify/evaluate any kind of expression. \n-Use 'solve' api when you need to find variable/variables value/values according to equation/equations.\nHere's the instruction part for both the \"solve\" and \"simplify\" APIs:\n\n### Solve API Request:\nConstruct a JSON object representing the request to solve equations with desired variables using the API named \"solve.\" Include the equations and desired variables in the request payload. Use the key \"name\" with the value \"solve\" to indicate the API name.\n\nExample:\n```json\n{\n  \"name\": \"solve\",\n  \"equations\": [\"3x + 2y = 10\", \"5x - y = 8\"],\n  \"desired_variables\": [\"x\", \"y\"]\n}\n```\n\n### Solve API Response:\nSimulate a JSON response from the \"solve\" API. The response should include a key-value pair for the solutions, a status indicator, and an optional message.\n\nExample:\n```json\n{\n  \"solutions\": {\n    \"x\": [\"2\"],\n    \"y\": [\"2\"]\n  },\n  \"status\": \"success\",\n  \"message\": \"Solutions found successfully\"\n}\n```\n\n### Simplify API Request:\nConstruct a JSON object representing the request to simplify a mathematical expression using the API named \"simplify.\" Include the expression in the request payload. Use the key \"name\" with the value \"simplify\" to indicate the API name.\n\nExample:\n```json\n{\n  \"name\": \"simplify\",\n  \"expression\": \"2x + 3x - (4 + x)\"\n}\n```\n\n### Simplify API Response:\nSimulate a JSON response from the \"simplify\" API. The response should include a key-value pair for the simplified expression, a status indicator, and an optional message.\n\nExample:\n```json\n{\n  \"simplified_expression\": \"4x - 4\",\n  \"status\": \"success\",\n  \"message\": \"Simplification successful\"\n}\n```\n"
const promptOp = "Solve the given question with step-by-step try to be concise. Don't use \\boxed for solutions."


const promptFt = "You are thinkercan, a math solver app. Your task is to provide step-by-step solutions for math and physics problems. You have two tools at your disposal: `simplify` to simplify expressions and `solve` to find solutions. Use these tools effectively to answer questions exclusively related to math and physics."
const stop = ["}\n```"];

const OpenAIConfig = {
  apiKey: process.env.REACT_APP_OPENAI_API_KEY, dangerouslyAllowBrowser: true

}


const openai = new OpenAI(OpenAIConfig);




  export const getStreamingCompletion = async ({ _messages, modelName }) => {
    let stop = ["}\n```"];
    let modelPrompt = promptFt;
    let OpenAIModel = process.env.REACT_APP_OPENAI_MODEL;

    if (modelName === "op"){
      modelPrompt = promptOp;
      OpenAIModel = "gpt-4o-mini";

      stop = null;
    }

    const systemMessage = {
      role: "system",
      content: modelPrompt
    };
    return openai.chat.completions.create({
        model: OpenAIModel,
        messages: [systemMessage, ..._messages],
        temperature: 0.0,
        max_tokens: 1000,
        top_p: 0.0,
        frequency_penalty:0,
        presence_penalty:0,
        stop: stop,
        stream: true,
    });
  };

  export const getSolverApiResponse = async ({ _apiPart }) =>{
    return fetch(process.env.REACT_APP_THINKER_API_KEY , {
        method: 'POST',
        body: JSON.stringify(_apiPart),
        headers: {
           'Content-type': 'application/json',
        },
     })
  }